<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="master">
        <heade></heade>
        <div class="con">
            <div class="box">
                <p class="p1">设置主管理员</p>
                <p class="p2">添加一个主管理员</p>
                <div class="div1"><span @click="tianjia">添加主管理员</span></div>
                <div class="div2" v-if="ming">
                    <p>管理员姓名</p>
                    <p>手机号</p>
                    <p>查看权限</p>
                    <p>编辑</p>
                </div>
                <div class="div3" v-if="ming">
                    <p>{{name}}</p>
                    <p>{{phone}}</p>
                    <p>{{quan}}</p>
                    <p style="color:#F16977;cursor:pointer;" @click="drawer = true">{{bian}}</p>
                </div>
                <p style="color:#888;font-size:0.12rem;text-align:center;margin-top:1rem;" v-if="!ming">您还未拥有主管理员，快去添加吧</p>
            </div>
        </div>
        <el-drawer
        :visible.sync="drawera"
        :modal='false'
        :show-close='false'
        title="添加成员"
        size="620">
            <div style="color:#888888;font-size:0.18rem;height:0.42rem;width:94%;background:#E7E8EB;margin:0 auto;line-height:0.42rem;padding:0 0.12rem;min-width:3.88rem;">人员信息</div>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>主管理员姓名
                </li>
                <li class="dd2"><input type="text" placeholder='请添加管理员名' v-model="name1"></li>
                <li class="dd1">
                    <span>*</span>手机号
                </li>
                <li class="dd2"><input type="text" placeholder="请输入管理员手机号" v-model="phone1"></li>
            </ul>
            <p style="color:#CCCCCC;font-size:0.14rem; text-align:center;margin-top:2.3rem;">将以短信形式通知主管理员</p>
            <div class="xuan_foot">
                <div style="color:#fff;background:#F96C6C" @click="box_surea">确定</div>
                <div style="color:#888888;border:0.01rem solid #888888" @click="drawera = false">取消</div>
            </div>
        </el-drawer>
        <el-drawer
        :visible.sync="drawer"
        :modal='false'
        :show-close='false'
        title="修改成员信息"
        size="620">
            <div style="color:#888888;font-size:0.18rem;height:0.42rem;width:94%;background:#E7E8EB;margin:0 auto;line-height:0.42rem;padding:0 0.12rem;min-width:3.88rem;">人员信息</div>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>主管理员姓名
                </li>
                <li class="dd2"><input type="text" placeholder='请添加管理员名' v-model="name1"></li>
            </ul>
            <ul class="liebiao2">
                <li class="dd1">
                    <span>*</span>手机号
                </li>
                <li class="dd2"><input type="text" placeholder="请输入管理员手机号" v-model="phone1"></li>
            </ul>
            <p style="color:#CCCCCC;font-size:0.14rem; text-align:center;margin-top:2.3rem;">将以短信形式通知主管理员</p>
            <div class="xuan_foot">
                <div style="color:#fff;background:#F96C6C" @click="box_sure">确定</div>
                <!-- <div style="color:#888888;border:0.01rem solid #888888" @click="box_no">删除</div> -->
                <div style="color:#888888;border:0.01rem solid #888888" @click="drawer = false">取消</div>
            </div>
        </el-drawer>
        <foote></foote>
    </div>
</template>

<script>
import heade from '../components/Heade'
import foote from '../components/Foote'
import { query_ent_primary_manager, update_ent_primary_manager, delete_ent_primary_manager, set_ent_primary_manager } from '../api/api.js'
export default {
  name: 'master',
  data () {
    return {
      name: '',
      phone: '',
      quan: '全部',
      bian: '编辑',
      drawera: false,
      drawer: false,
      ent_ida: '',
      name1: '',
      phone1: '',
      ming: false
    }
  },
  created () {
    if (sessionStorage.getItem('pc_ent_id') != undefined) {
      this.ent_ida = JSON.parse(JSON.parse(sessionStorage.getItem('pc_ent_id'))).ent_info.ent_id
    }
  },
  mounted () {
    this.jichu()
    // console.log(this.ent_ida)
  },
  components: { heade, foote },
  methods: {
    jichu () {
      query_ent_primary_manager({
        data: {
          ent_id: this.ent_ida
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10103) {
          if (JSON.parse(res.data.body.data) != null) {
            const xin = JSON.parse(res.data.body.data)
            console.log(xin)
            if (xin.p_mng_name != null && xin.p_mng_mobile != undefined) {
              this.name = xin.p_mng_name
              this.phone = xin.p_mng_mobile
              this.name1 = xin.p_mng_name
              this.phone1 = xin.p_mng_mobile
              this.ming = true
            } else {
              this.ming = false
            }
          } else {
            this.ming = false
          }
        } else if (res.data.code == 10104) {
          this.$message({
            message: '获取失败',
            type: 'error'
          })
        }
      })
    },
    // 添加主管理员
    tianjia () {
      if (this.ming) {
        this.$message({
          message: '只可拥有一个主管理员哟',
          type: 'warning'
        })
      } else {
        this.drawera = true
      }
    },
    // 添加框确定
    box_surea () {
      if (this.name1.length > 0 && /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.phone1)) {
        console.log(11111)
        set_ent_primary_manager({
          data: {
            mobile: this.phone1,
            name: this.name1,
            ent_id: this.ent_ida
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10087) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            sessionStorage.setItem('pc_guanli_name', JSON.stringify(this.name1))
            this.$router.push('/primaryent')
          } else if (res.data.code == 10088) {
            this.$message({
              message: '添加失败',
              type: 'error'
            })
          } else if (res.data.code == 12001) {
            this.$message({
              message: '此账号已退出企业，不能添加为主管理员',
              type: 'error'
            })
          }
        })
      } else {
        this.$message({
          message: '请填写完整的信息',
          type: 'error'
        })
      }
    },
    // 框确定
    box_sure () {
      if (this.name1.length > 0 && /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.phone1)) {
        console.log(11111)
        update_ent_primary_manager({
          data: {
            mobile: this.phone1,
            name: this.name1,
            ent_id: this.ent_ida,
            mobile_old: this.phone
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10105) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.drawer = false
            this.jichu()
          } else if (res.data.code == 10106) {
            this.$message({
              message: '修改失败',
              type: 'error'
            })
          } else if (res.data.code == 600) {
            this.$message({
              message: '员工账号不存在',
              type: 'error'
            })
          } else if (res.data.code == 12001) {
            this.$message({
              message: '此账号已退出企业，不能添加为主管理员',
              type: 'error'
            })
          }
        })
      } else {
        this.$message({
          message: '请填写完整的信息',
          type: 'error'
        })
      }
    },
    // 框删除
    box_no () {
      delete_ent_primary_manager({
        data: {
          ent_id: this.ent_ida
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10107) {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.jichu()
          this.drawer = false
          this.name = ''
          this.phone = ''
          this.name1 = ''
          this.phone2 = ''
        } else if (res.data.code == 10108) {
          this.$message({
            message: '删除失败',
            type: 'error'
          })
        }
      })
    },
    home () {
      this.$router.push('/enterprise')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.master {
    background: #E7E8EB;
    min-height:100vh;
    .con{
        width:100%;
        height: 65.6%;
        background: #E7E8EB;
        padding-top: 1.9rem;
        margin-bottom: 2.76rem;
        .box{
            height:3.39rem;
            width:11.12rem;
            background: #fff;
            margin:0 auto;
            padding: 0 0.44rem;
            .p1{
                font-size: 0.18rem;
                color:#1A2533;
                font-weight: 500;
                padding-top:0.19rem;
                margin-bottom: 0.08rem;
            }
            .p2{
                font-size: 0.12rem;
                color:#A9A9A9;
                margin-bottom: 0.11rem;
            }
            .div1{
                height:0.42rem;
                width:100%;
                background-color: #E7E8EB;
                display: flex;
                align-items: center;
                span{
                    height: 0.25rem;
                    width:0.98rem;
                    background: #fff;
                    border:0.01rem solid #A9A9A9;
                    font-size: 0.12rem;
                    color:#888888;
                    line-height: 0.25rem;
                    text-align: center;
                    display: block;
                    margin-left: 0.22rem;
                    cursor:pointer;
                }
            }
            .div2{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 0.25rem;
                margin-top:0.25rem;
                margin-bottom: 0.07rem;
                p{
                    font-size: 0.12rem;
                    color:#3C3A3E;
                }
            }
            .div3{
                border-top:0.01rem solid #eee;
                border-bottom: 0.01rem solid #eee;
                display: flex;
                align-items: center;
                justify-content: space-between;
                p{
                    padding: 0.25rem;
                    font-size: 0.12rem;
                    color:#4C4A4D;
                }
            }
        }
    }
    @media screen and (max-width: 12rem) {
      .con {
        width: 12rem;
      }
    }
}
</style>
